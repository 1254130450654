<template lang="pug">
.main
    .sticky-top
        .loader
            .bar(v-if="$store.getters.loading")
        .submenu
            .container-xxl

                .row.no-gutters.submenu-inner.mb-3
                    .col-7
                        .headline-submenu User profile
            
                           
              
    .container-xxl
        .row.no-gutters
            .col-12
                router-view(name="main").main-view
            
</template>

<script>
export default {
  name: 'SubmenuUserProfile',
  props: {
    title: String,
    
  },

  data: function () {
      return {
         
      }
  },

  mounted: function() {
     
  },

  methods: {


  },
  computed: {
    
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
    
    @import '@/_vars.scss';
 

</style>
