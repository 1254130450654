<template lang="pug">

    .profile
        .row
            .col-12.col-lg-3( v-if="edit_profile_details" )
                h-card.profile-box
                    .profile-pic-container.mx-auto.text-center
                        .cropper-pseudo-container( v-if="show_cropper" )
                            .cropper-container
                                b-btn-close.close-icon( @click="closeCropperPopup" )
                                cropper.cropper( 
                                        @change="onCroppedImageUpdate" 
                                        :src="uploaded_image" 
                                        :stencil-props="{ aspectRatio: 1/1}"
                                        fluid 
                                        )
                                b-btn.mt-5(size="sm", variant="outline-primary", @click="saveCroppedImage").mt-2.crop-image Select Image
                        
                        .profile-pic
                            b-img( v-set-image-src="temp_profile_data.user_image" fluid )
                            b-btn( size="sm", variant="outline-primary", @click="showFileUpload").mt-2.edit-image Change image

                        input( type="file" accept="image/*" @change="uploadImage" id="file-input" ).file_uploader

                        b-form(horizontal).mt-1
                            b-form-group()
                                b-input(v-model="temp_profile_data.full_name")
                            .clearfix( v-if = "!update_in_progress")
                                b-btn(size="sm", variant="outline-primary", @click="cancelProfileDetailsChanges").mt-2.mr-5.float-left Cancel
                                b-btn(size="sm", variant="primary", @click="saveProfileDetails").mt-2.float-right Save Changes
                                
            .col-12.col-lg-3( v-else )
                h-card.profile-box
                    .profile-pic-container.mx-auto.text-center
                        .profile-pic
                          b-img( v-set-image-src="profile_data.user_image" fluid )
                        .clearfix
                            .name-details.float-left
                                .mt-3.h4.text-left {{profile_data.full_name}}
                                a.mt-1.text-left.small.user-email( :href="'mailto:' + profile_data.email" ) {{profile_data.email}}

                            b-btn.float-right( size="sm", variant="outline-primary", v-if="is_me" @click="editProfileDetails()").mt-4 Edit

            .col-12.col-lg-9( v-if="edit_interest_and_expertise" )
                h-card.profile-box(title="User information")
                    .interest-heading Interests and Expertise
                    .interest-desc.small In order to personalise your experience please give us the following information

                    .mt-4.p-3.block-holders( :key="'select-expertise-tags'")
                        .p-4.block-content
                            taxonomy-tagger.small(
                                    :id="'select-expertise-tags'"
                                    :title="'What are your fields of expertise?'"
                                    :multiple="true"
                                    :taggable="true"
                                    :select-on-tab="true"
                                    :close-on-select="false"
                                    :placeholder="'Type to search for Expertise'"
                                    :selections="temp_profile_data.expertise"
                                    :tags_list="expertise_tagger_list"
                                    :can_be_empty="true"
                                    v-model="temp_profile_data.expertise")

                    .mt-3.p-3.block-holders( :key="'select-interests-tags'")
                        .p-4.block-content
                            taxonomy-tagger.small(
                                    :id="'select-interests-tags'"
                                    :title="'What are you interested in?'"
                                    :multiple="true"
                                    :taggable="true"
                                    :select-on-tab="true"
                                    :close-on-select="false"
                                    :placeholder="'Type to search for Interests'"
                                    :selections="temp_profile_data.interests"
                                    :tags_list="interests_tagger_list"
                                    :can_be_empty="true"
                                    v-model="temp_profile_data.interests")
                    .clearfix( v-if = "!update_in_progress")
                        b-btn(size="sm", variant="outline-primary", @click="cancelInterestAndExpertiseChanges").mt-5.mr-5.float-left Cancel
                        b-btn(size="sm", variant="primary", @click="saveInterestAndExpertise").mt-5.float-right Save Changes
                    
                    
            .col-12.col-lg-9( v-else )
                h-card.profile-box(title="User information")  
                    .clearfix  
                        .interest-heading.float-left Interests and Expertise
                        b-btn( size="sm", variant="outline-primary", secondaryv-if="is_me" @click="editInterestAndExpertise").float-right Edit
                    
                    .mt-4.px-3.py-2.block-holders Expertise
                        .p-2.pt-3.block-content( block ) 
                            taxonomy-tagger.small(
                                    :id="'select-expertise-tags-1'"
                                    :topic_type="expertise_type",
                                    :selections="profile_data.expertise"
                                    :read_only="true"
                                    v-model="profile_data.expertise"
                                    :tags_list="expertise_tagger_list"
                                    :can_be_empty="true"
                                    :key="'select-expertise-tags-1'")

                    .mt-3.px-3.py-2.block-holders Interests
                        .p-2.pt-3.block-content( block )
                            taxonomy-tagger.small(
                                    :id="'select-interests-tags-1'"
                                    :selections="profile_data.interests"
                                    :read_only="true"
                                    v-model="profile_data.interests"
                                    :tags_list="interests_tagger_list"
                                    :can_be_empty="true"
                                    :key="'select-interests-tags-1'")
            
            .col-12.col-lg-9.offset-lg-3.mt-2(  v-if="is_me"  )
                h-card.profile-box(title="Settings")                 
                  .notifications-holder.mb-5( v-if="edit_notifications" )
                    .mt-2.p-3.block-holders
                      .notification-channels.pt-2
                        .text.pb-2 Notifications channel
                        b-checkbox.ml-4.mb-2( v-model="notifications_setting.email" ) Receive notifications via email.
                        //b-checkbox.ml-4.mb-2( v-model="notifications_setting.msteams" ) Receive notifications via Microsoft Teams.

                      .send-digest.pt-4
                        .text.pb-2 Weekly digest
                        b-checkbox.ml-4.mb-2( v-model="notifications_setting.send_weekly_digest" ) Receive weekly digest.
                        b-btn.ml-4(size="sm", variant="primary",  @click="sendTestTeamsMsg" )  Send test
                        
                      //.input-holder.mb-1.text-left.pt-4
                        .text.mb-1() Microsoft Team id
                        b-input(v-model="notifications_setting.msteams_id" )

                    .clearfix( v-if = "!update_in_progress")
                        b-btn( variant="secondary" @click="cancelNotificationChanges").mt-5.mr-5.float-left Cancel
                        b-btn( variant="primary" @click="saveNotificationChanges").mt-5.float-right Save Changes
                  .notifications-holder.mb-5( v-else )  
                    .clearfix  
                      b-btn( size="sm" @click="editNotificationSettings").float-right Edit
                    .mt-4.p-3.block-holders
                      .p-2.block-content
                        .notification-channels.pb-2
                          .all-selected.h6( v-if="user.notification_channels && user.notification_channels.indexOf('email') != -1 && user.notification_channels.indexOf('msteams') != -1" ) You will recieve notifications via email and Microsoft Teams.
                          .email-selected.h6( v-else-if="user.notification_channels && user.notification_channels.indexOf('email') != -1" ) You will recieve notifications via email.
                          //.msteams-selected.h6( v-else-if="user.notification_channels && user.notification_channels.indexOf('msteams') != -1" ) You will recieve notifications via Microsoft Teams.
                          .none-selected.h6( v-else ) You are not recieving notifications currently, please edit the setting to update.
                        
                        .send-digest
                          .digest-opted.h6( v-if="user.send_weekly_digest" ) You have opted for weekly digest.
                          .digest-not-opted.h6( v-else ) You have not opted for weekly digest, please edit the setting to update..
                        
                        //.db.clearfix.mt-4
                          .mt-1.h6.text-left Microsoft Teams id : {{user.msteams_id || "N.A."}}
                             
</template>

<script>


import Vue from 'vue';
import { validationMixin } from "vuelidate"
import { mapFields } from 'vuex-map-fields';
import { Cropper } from "vue-advanced-cropper";
import Config from '@/config';
import TaxonomyTagger from '@/components/TaxonomyTagger';
import 'vue-advanced-cropper/dist/style.css';

export default {
    name: 'UserProfile',

    data () {
        return {
         
            profile_data: {},
            edit_interest_and_expertise: false,
            edit_profile_details: false,
            edit_notifications: false,
            show_cropper: false,
            uploaded_image: null,
            cropped_image: null,
            temp_profile_data: {},
            interest_type: "industry",
            expertise_type: "vertical",
            is_me: false,
            update_in_progress: false,
            tagger_options: {
                              industry: null,
                              vertical: null,
                              enterprise_function: null,
                            },
            expertise_tagger_list: [],
            interests_tagger_list: [],
            notifications_setting: {}
        }
    },
    mixins: [
        validationMixin
    ],

    computed: {
        ...mapFields([
            'user'
        ]),
    },
     validations: {
    },
    watch: {
        // call again the method if the route changes
        '$route.params.id': function() {
            this.initiateComponent();
        } 
      
    },
    created: function () {
        
    },
    mounted: function() {

        this.initiateComponent();

    },
    methods: {


        initiateComponent() {

            this.is_me = this.$route.params.id == this.user.id;
           

            this.$api.get_user(this.$route.params.id).then((res) => {
                this.profile_data = {
                    full_name: res.full_name,
                    email: res.email,
                    user_image: res.profile_pic,
                    expertise: res.expertise || [],
                    interests: res.interests || []
                }
                this.initiateTaxonomyTaggerdata();
            });
        },

        initiateTaxonomyTaggerdata(){
          this.fetchTopic("enterprise_function");
          this.fetchTopic("industry");
          this.fetchTopic("vertical");
        },
        fetchTopic( topic_type ){
          if(this.$store.state.topic_type_cache[topic_type]) {
            this.tagger_options[ topic_type ] = this.$store.state.topic_type_cache[topic_type];
            this.checkAndParseTaggerOptions();
          } else {
            Vue.http.get(`${Config.config.base_host}/topics_v1?topic_type=${escape(topic_type)}`).then(res => {
                this.$store.state.topic_type_cache[topic_type] = res.body;
                this.tagger_options[ topic_type ] = res.body;  
                console.log("Fetched tags for ", topic_type);
                this.checkAndParseTaggerOptions();
            });

          }
        },
        checkAndParseTaggerOptions(){
          if( 
                 this.tagger_options.enterprise_function
              && this.tagger_options.industry
              && this.tagger_options.vertical
            ){
                let enterprise_list = this.tagger_options.enterprise_function.map( tag => tag.name  ),
                    industry_list   = this.tagger_options.industry.map( tag => tag.name  ),
                    vertical_list   = this.tagger_options.vertical.map( tag => tag.name  );

                this.expertise_tagger_list = this.$_.uniq( [ ...enterprise_list, ...industry_list, ...vertical_list, ...this.profile_data.expertise  ] );
                this.interests_tagger_list = this.$_.uniq( [ ...enterprise_list, ...industry_list, ...vertical_list, ...this.profile_data.interests  ] );
            }
        },

        showFileUpload(){
            document.getElementById( "file-input" ).click();
        },

        uploadImage( event ){
            const image = event.target.files[0];
            const reader = new FileReader();
            reader.readAsDataURL(image);
            reader.onload = (e)=>{
                this.uploaded_image = e.target.result;
                document.getElementById( "file-input" ).value = "";
            };

            this.show_cropper = true;

        },

        onCroppedImageUpdate( { canvas } ){
            this.cropped_image = canvas.toDataURL();
        },

        saveCroppedImage(){
            this.$store.dispatch('start_loading');
            this.$api.upload_image( this.cropped_image || this.uploaded_image ).then((res) => {
                this.temp_profile_data.user_image = `${Config.config.base_host}/images/` + res._id;
                this.closeCropperPopup();
                this.$store.dispatch("stop_loading");
            });
            

        },

        closeCropperPopup(){
            this.uploaded_image = null;
            this.cropped_image = null;
            this.show_cropper = false;
        },

        editProfileDetails(){
            this.temp_profile_data.full_name = this.profile_data.full_name;
            this.temp_profile_data.user_image = this.profile_data.user_image;
            
            this.edit_profile_details = true;
            this.closeInterestAndExpertiseEditView();
        },

        saveProfileDetails(){
            let dataToPost = this.$_.cloneDeep( this.user );
            this.profile_data.full_name  = this.temp_profile_data.full_name;
            this.profile_data.user_image = this.temp_profile_data.user_image;
            
            dataToPost.full_name         = this.profile_data.full_name;
            dataToPost.profile_pic       = this.profile_data.user_image;
            
            this.makeUserUpdateCall( dataToPost, this.closeProfileEditView );

        },

        cancelProfileDetailsChanges(){
            this.closeProfileEditView();
        },

        cancelNotificationChanges(){
          this.closeNotificationChanges();
        },
        closeNotificationChanges(){
          this.edit_notifications = false;
        },
        saveNotificationChanges(){
            let dataToPost = this.$_.cloneDeep( this.user );
            
            dataToPost.send_weekly_digest = this.notifications_setting.send_weekly_digest;
            dataToPost.msteams_id = this.notifications_setting.msteams_id;
            dataToPost.notification_channels = [];
            this.notifications_setting.email && dataToPost.notification_channels.push("email");
            this.notifications_setting.msteams && dataToPost.notification_channels.push("msteams");

            this.notifications_setting = {};
            this.makeUserUpdateCall( dataToPost, this.closeNotificationChanges );
        },

        closeProfileEditView(){
          this.edit_profile_details = false;
          this.show_cropper = false;
        },


        editNotificationSettings(){
          this.edit_notifications     = true;

          this.notifications_setting  = {
                                          send_weekly_digest: this.user.send_weekly_digest,
                                          msteams_id: this.user.msteams_id,
                                          email: this.user.notification_channels.indexOf("email") != -1,
                                          msteams: this.user.notification_channels.indexOf("msteams") != -1,
                                        };

          this.closeProfileEditView();
          this.cancelProfileDetailsChanges();
        },

        sendTestTeamsMsg(){
          this.update_in_progress = true;
          Vue.http.get(`${Config.config.base_host}/notifications/weekly_digest_news/`).then(res => {
            console.log( res );
            this.$bvToast.toast("Weekly digest is sent via the selected channels.", {
                title: 'Success',
                variant: 'success',
                autoHideDelay: 3000,
            });
            this.update_in_progress = false;
          });
        },

        editInterestAndExpertise(){
            this.temp_profile_data.expertise = this.$_.cloneDeep( this.profile_data.expertise );
            this.temp_profile_data.interests = this.$_.cloneDeep( this.profile_data.interests );
            this.edit_interest_and_expertise = true;
            this.closeProfileEditView();
        },

        saveInterestAndExpertise(){
            let dataToPost = this.$_.cloneDeep( this.user );
            this.profile_data.expertise = this.$_.cloneDeep( this.temp_profile_data.expertise );
            this.profile_data.interests = this.$_.cloneDeep( this.temp_profile_data.interests );

            dataToPost.expertise = this.profile_data.expertise;
            dataToPost.interests = this.profile_data.interests;
            
            this.makeUserUpdateCall( dataToPost, this.closeInterestAndExpertiseEditView );
        },

        cancelInterestAndExpertiseChanges(){
            this.closeInterestAndExpertiseEditView();
        },

        closeInterestAndExpertiseEditView(){
            this.edit_interest_and_expertise = false;
        },

        makeUserUpdateCall( dataToPost, successCb ){
            this.update_in_progress = true;
            this.$store.dispatch("start_loading");
            this.$auth.saveUserData(dataToPost).then(() => {
                successCb && typeof(successCb) == "function" && successCb();
                this.$auth.getUserData().then((resp) => {
                  this.$store.commit("LOAD_USER", resp.user);
                });
                this.$store.dispatch("stop_loading");
                this.update_in_progress = false;
            });
        }
        
    },
    components: {
        Cropper,
        TaxonomyTagger
    }
}
</script>

<style scoped lang="scss">

    @import '@/_vars.scss';

    .profile {
        .profile-box{
            min-height: 400px;
        }
        .profile-pic-container{
            max-width: 300px;
            display: block;
        }
        .profile-pic{
            min-width: 200px;
            min-height: 200px;
            background-color: $hyg4;
            max-width: 240px;
            border-radius: 5px;
            position: relative;
        }
        .edit-image{
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate( -50%, -50% );
        }
        .user-email{
            display: block;
        }
        .block-holders {
            background: $hyg4;
            border-radius: 5px;
        }
        .file_uploader{
            visibility: hidden;
        }
        .cropper-pseudo-container{
            position: fixed;
            left: 0px;
            top: 0px;
            right: 0px;
            bottom: 0px;
            z-index: 1021;
            background: rgba($gray-600, 0.8);;
        }
        .cropper-container {        
            position: fixed;
            left: 50%;
            top: 50%;
            z-index: 1022;
            max-width: 1050px;
            max-height: 550px;
            transform: translate(-50%, -50%);
            width: 75vw;
            height: 75vh;
            border-radius: 5px;
            background: $hyg3;
            box-sizing: border-box;
            padding: 30px 50px 70px;
            border: 1px solid $hyg4;
        }
        .close-icon {
            font-size: 2em;
            position: absolute;
            right: 15px;
            top: 15px;
            cursor: pointer;
            z-index: 2;
        }
        .crop-image{
            position: absolute;
            left: 50%;
            bottom: 20px;
            transform: translate(-50%, 0%);
        }
    }


</style>


<style lang="scss">

    @import '@/_vars.scss';
    .vue-advanced-cropper__background, .vue-advanced-cropper__foreground{
      background: $hyg3;
    }
</style>